import React from "react"
import close from "../../../images/newLayout/close-black.svg"

function AuhtorIntro( {isExpanded, toggleExpansion, authorVideoUrl} ) {

  return (
    <button
      className={`${ isExpanded ? `flex` : `hidden` } fixed top-0 left-0 w-screen h-screen justify-center items-center bg-strongGray bg-opacity-80 z-50`}
      onClick={() => toggleExpansion(!isExpanded)}
    >
      <div className="aboslute flex flex-col items-end pb-2 px-2 bg-white rounded-md mx-auto max-w-xs md:max-w-screen-md">
        <button onClick={() => toggleExpansion(!isExpanded)}>
          <img className="w-4 h-4 m-2 cursor-pointer" src={close} alt="fechar" />
        </button>
        <iframe
          className="videoPopupSize"
          title="Stéfano Willig - CEO da Awise e autor do Blog do QuantoSobra"
          src={authorVideoUrl}
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
    </button>
  )
}

export default AuhtorIntro