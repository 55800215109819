import React from "react"
import { Link } from "gatsby"

import PostsLayout from "../../components/postsLayout"
import moreIcon from "../../images/newLayout/moreIcon.svg"

function AuthorPosts( { posts, authorName, isAuthorPage, nextPagePath, previousPagePath, uri } ) {

  return (
    <div className={`${!isAuthorPage ? "mt-0" : "md:my-20"} flex flex-col gap-6 my-12 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl`}>

      <h2 className="mx-auto font-bold text-3xl w-60 md:w-auto text-center">Artigos de <strong className="text-middleGreen font-bold">{authorName}</strong></h2>

      <p className="mx-auto md:w-1/2 md:mb-10 text-xl text-center">De forma prática, o QuantoSobra permite uma gestão completa, eficiente e sem dores de cabeça.</p>

      <PostsLayout posts={posts} nextPagePath={nextPagePath} previousPagePath={previousPagePath} isAuthorPage={isAuthorPage ? true : false} uri={uri} />

      <Link to={uri} className={`${!isAuthorPage ? "hidden" : "flex"} mx-auto md:mt-10 flex-col md:flex-row items-center gap-2 md:gap-4`}>
        <img src={moreIcon} alt="mostrar mais" />
        <p className="text-xl text-center w-56 md:w-full">Ver todos os artigos de Stéfano</p>
      </Link>

    </div>
  )
}

export default AuthorPosts