import React from "react"

import CardPost from "../cardPost"
import PagesNavigation from "./pagesNavigation"

function postsLayout( { posts, nextPagePath, previousPagePath, isAuthorPage, uri } ) {

  return(

    <section className="mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">

      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {posts.map((post, i) => <CardPost key={i} post={post} templatePage="true" />)}
      </ul>

      {!isAuthorPage ? <PagesNavigation nextPagePath={nextPagePath} previousPagePath={previousPagePath} uri={uri} /> : null }

    </section>
  )
}

export default postsLayout