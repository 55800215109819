import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Layout from "../components/newLayout/layout"
import Seo from "../components/seo"
import UnderHeader from "../components/underHeader/underHeader"
import AuhtorIntro from "../components/author/authorIntro"
import AuthorPosts from "../components/author/authorPosts"
import Form from "../components/newLayout/form"

function Stefano({ data, pageContext: { nextPagePath, previousPagePath, userUri } }) {

  const authorImage = getImage(data.stefanoImg)
  const authorBio = "Apaixonado por empreendedorismo e gestão de negócios, Stéfano estuda constantemente sobre administração de empresas. O objetivo é colocar em prática todo esse conhecimento para ajudar a sua empresa acrescer."
  const authorPosition = "CEO do QuantoSobra"
  const authorName = "Stéfano Willig"
  const authorLinkedin = "stefanowillig"
  const authorVideoUrl = "https://www.youtube.com/embed/d2_dpOsKtR4"

  const posts = data.allWpPost.nodes

  return (
    <Layout>

      <Seo title="Stefano Willig" />

      <UnderHeader />

      <AuhtorIntro authorImage={authorImage} authorBio={authorBio} authorPosition={authorPosition} authorName={authorName} authorLinkedin={authorLinkedin} authorVideoUrl={authorVideoUrl} />

      <AuthorPosts posts={posts} authorName="Stéfano Willig" nextPagePath={nextPagePath} previousPagePath={previousPagePath} uri={userUri} />

      {/*<Form />*/}

    </Layout>
  )
}

export default Stefano

export const pageQuery = graphql`
  query WordPressAuthorStefanoPosts($offset: Int!, $postsPerPage: Int!) {

    stefanoImg: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "stefano.png"}) {
      childImageSharp {
        gatsbyImageData(
          width: 576,
          placeholder: BLURRED,
          formats: [WEBP],
          quality: 100
        )
      }
    }

    allWpPost(
      filter: {author: {node: {id: {in: "dXNlcjox"}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "DD/MM/YYYY")
        title
        author {
          node {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400,
                  placeholder: BLURRED,
                  formats: [WEBP],
                  quality: 100
                )
              }
            }
          }
        }
        categories {
          nodes {
            name
          }
        }
      }
    }

    wpUser(id: {in: "dXNlcjox"}) {
      name
      description
      avatar {
        url
      }
    }
  }
`