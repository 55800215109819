import React from "react"
import { Link } from "gatsby"

function postsLayout( { nextPagePath, previousPagePath, uri } ) {

  function definePagerColor(element, isText) {
    if (!element) {
      if (isText) {
        return "textHailGray"
      }
      return "pagerHailGray"
    }
    if (isText) {
      return "textStrongGray font-medium"
    }
    return "pagerMetallic"
  }

  function navigatePagePath(page) {
    if (page) {
        return (
          <Link to={page} className="flex flex-row items-center">
          {defineArrowDirection(page)}
        </Link>
      )
    }
    else if (page === previousPagePath) {
      return (
        <div className="flex flex-row items-center">
          {defineArrowDirection(previousPagePath)}
        </div>
      )
    }
  }

  function goToStart() {
    return (
      <Link to={uri || "/"} className={`${previousPagePath ? "md:flex" : "hidden"} ${nextPagePath ? "hidden" : "md:flex"} flex-row items-center`}>
        <span className="uppercase textStrongGray font-medium">Início</span>
      </Link>
    )
  }

  function defineArrowDirection(page) {
    if (page === nextPagePath) {
      return (
        <>
          <span className={`uppercase ${definePagerColor(page, true)}`}>Próximo</span>
          <div className="relative w-8 h-8 bg-white">
            <div className={`absolute w-1 h-4 top-1 left-4 transform -rotate-45 rounded-xl ${definePagerColor(nextPagePath)}`}/>
            <div className={`absolute w-1 h-4 bottom-1 right-3 transform rotate-45 rounded-xl ${definePagerColor(nextPagePath)}`}/>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="relative w-8 h-8 bg-white">
          <div className={`absolute w-1 h-4 top-1 right-3 transform rotate-45 rounded-xl ${definePagerColor(previousPagePath)}`}/>
          <div className={`absolute w-1 h-4 bottom-1 left-4 transform -rotate-45 rounded-xl ${definePagerColor(previousPagePath)}`}/>
        </div>
        <span className={`uppercase ${definePagerColor(page, true)}`}>Anterior</span>
      </>
    )
  }

  return(
    <div className="mx-auto w-full md:w-8/12  flex justify-between my-10">

      { navigatePagePath(previousPagePath) }

      { goToStart() }

      { navigatePagePath(nextPagePath) }

    </div>
  )
}

export default postsLayout