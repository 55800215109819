import React, {useState} from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import play from "../../images/newLayout/play.svg"
import linkedin from "../../images/newLayout/linkedin.svg"
import aspas from "../../images/newLayout/aspas.svg"
import VideoPopup from "./stefano/videoPopup"

function AuhtorIntro( {authorImage, authorBio, authorPosition, authorName, authorLinkedin, authorVideoUrl} ) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <section className="flex flex-col -mt-24 md:-mt-44 lg:-mt-56 xl:-mt-60 mb-12 lg:mb-20 mx-auto max-w-xs md:max-w-screen-md lg:max-w-screen-lg text-center md:text-left">

      <div className="relative mb-12 md:mb-6 flex flex-col md:flex-row items-center justify-center">
        <div className="relative inline-block mb-6 md:absolute md:right-24 lg:right-28 xl:right-20 md:top-14">
          <GatsbyImage className="w-auto md:max-w-sm lg:max-w-lg xl:max-w-xl" image={authorImage} alt={`Foto do ${authorImage}`} />
          <button onClick={() => toggleExpansion(!isExpanded)} className="absolute right-0 bottom-0 flex flex-row gap-2 cursor-pointer">
            <span className="hidden lg:flex pt-2">Conheça melhor!</span>
            <img src={play} alt="Icone de play" />
          </button>
        </div>

        <div className="md:absolute flex flex-col md:left-24 lg:left-28 xl:left-20 md:top-32 lg:top-40 xl:top-48">
          <h1 className="font-black text-5xl lg:text-6xl xl:text-7xl uppercase mb-4 md:w-96">{authorName}</h1>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <p className="mb-4 md:mb-0 text-lg">{authorPosition}</p>
            <a href={`https://www.linkedin.com/in/${authorLinkedin}/`} target="_blank" rel="noreferrer" className="md:order-first">
              <img className="mx-auto" src={linkedin} alt="Ícone do LinkedIn" />
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-5 md:items-start mtBio md:px-12 lg:px-28 xl:px-20">
        <img className="mx-auto" src={aspas} alt="aspas" />
        <p className="text-xl">{authorBio}</p>
      </div>

      {<VideoPopup isExpanded={isExpanded} toggleExpansion={toggleExpansion} authorVideoUrl={authorVideoUrl} />}

    </section>
  )
}

export default AuhtorIntro